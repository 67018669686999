import type {
  ClickAnalyticsFunctionType,
  ClickEvent,
  ScrollAnalyticsFunctionType,
  ScrollEvent,
} from 'constants/gtmEvent';

import pushGmtEvent from 'utils/pushGmtEvent';

export default function useAnalytics(): {
  clickAnalytics: ClickAnalyticsFunctionType;
  scrollAnalytics: ScrollAnalyticsFunctionType;
} {
  function clickAnalytics(event: ClickEvent): void {
    pushGmtEvent({
      event: 'mos_click',
      sourceScreen: event.sourceScreen,
      name: event.name,
      businessType: 'b2c',
      SBN: '1',
    });
  }

  function scrollAnalytics(event: ScrollEvent): void {
    pushGmtEvent({
      event: 'mos_scroll',
      percent: event.percent.toString(),
      businessType: 'b2c',
      SBN: '3',
    });
  }

  return { clickAnalytics, scrollAnalytics };
}
