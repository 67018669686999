import { analyticsScript } from 'analytics/AnalyticScript';
import type { PublicRuntimeConfig } from 'app.types';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import Script from 'next/script';

import ScrollAnalytics from 'components/basic/ScrollAnalytics';

import 'styles/globals.scss';

function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: Record<string, string> }): JSX.Element {
  const { publicRuntimeConfig } = getConfig();
  const { analytics }: PublicRuntimeConfig = publicRuntimeConfig;

  if (analytics.enable) {
    return (
      <ScrollAnalytics>
        <Script id="AnalyticsScriptFunc.script" strategy="beforeInteractive">
          {analyticsScript}
        </Script>
        <Component {...pageProps} err={err} />
      </ScrollAnalytics>
    );
  }

  return <Component {...pageProps} err={err} />;
}

export default App;
