import useAnalytics from 'hooks/useAnalytics';
import { useEffect } from 'react';
import type { FC } from 'react';

const ScrollAnalytics: FC = ({ children }) => {
  const { scrollAnalytics } = useAnalytics();

  useEffect(() => {
    let is25PercentReached = false;
    let is50PercentReached = false;
    let is75PercentReached = false;
    let is100PercentReached = false;

    function scrollListener(): void {
      const scrolled = window.scrollY;
      const height = document.body.scrollHeight - window.innerHeight;
      const percent = (scrolled / height) * 100;

      if (percent > 25 && !is25PercentReached) {
        is25PercentReached = true;
        scrollAnalytics({ percent: 25 });
      }

      if (percent > 50 && !is50PercentReached) {
        is50PercentReached = true;
        scrollAnalytics({ percent: 50 });
      }

      if (percent > 75 && !is75PercentReached) {
        is75PercentReached = true;
        scrollAnalytics({ percent: 75 });
      }

      if (percent >= 100 && !is100PercentReached) {
        is100PercentReached = true;
        scrollAnalytics({ percent: 100 });
      }
    }

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [scrollAnalytics]);

  return <div>{children}</div>;
};

export default ScrollAnalytics;
