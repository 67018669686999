export interface DataLayerObj {
  event: 'mos_click' | 'mos_scroll';
  SBN: '1' | '3';
  businessType: 'b2c';
  sourceScreen?: string;
  name?: string;
  percent?: string;
}

export default function pushGmtEvent(data: DataLayerObj): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}
